import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import ErrorBoundary from 'Components/ErrorBoundary';
import ErrorPage from 'Components/ErrorPage';
import HealthCheck from 'Components/HealthCheck';
import Loader from 'Components/Loader';
import { selectBorrowerEmail } from 'Redux_Store/selectors';
import { useAppSelector } from 'Shared/utils';
import clevertap from 'clevertap-web-sdk';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from './globalStyles';
const Home = React.lazy(() => import('Features/Home/Home.connector'));

const App: React.FC = () => {
  const borrowerEmail = useAppSelector(selectBorrowerEmail);
  const { loanAccountNumber } = useAppSelector(
    (store) => store.borrowerWorkflow.workflowDetails
  );

  useEffect(() => {
    amplitude.init(
      process.env.REACT_APP_AMPLITUDE_API_KEY || '',
      borrowerEmail,
      {
        defaultTracking: true,
      }
    );
    clevertap.init('W4W-5K7-K75Z', 'in1');
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: false });
    clevertap.spa = true;
  }, [borrowerEmail]);

  useEffect(() => {
    if (loanAccountNumber) {
      clevertap.profile.push({
        Site: { Identity: loanAccountNumber, account_no: loanAccountNumber },
      });
    }
  }, [loanAccountNumber]);

  return (
    <ErrorBoundary>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <ToastContainer />
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/allocation/:customerHashCode"
                component={Home}
              />
              <Route exact path="/health-check" component={HealthCheck} />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
